:root {
  --main-font: whitesmoke;
  --foreground: rgb(33, 37, 41);
  --background: rgb(1, 1, 0);
  --bs-body-bg: var(--background);
}

body {
  margin: 0;
  font-family: "Marvel", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--main-font);
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.5rem;
}

p {
  font-family: "Montserrat", sans-serif;
}
nav.navbar.navbar-light {
  background-color: var(--background);
  color: var(--main-font);
}

a.navbar-brand {
  font-size: 1.8rem;
}

a.nav-link {
  text-align: end;
}

nav {
  border-bottom: 1.5px solid var(--foreground);
}

.fade-in-section {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.fade-in-section.is-visible {
  opacity: 1;
}

.navbar-collapse {
  flex-grow: 0;
  justify-content: end;
}

.section-container {
  padding-bottom: 50px;
  min-height: calc(100vh - 57px);
  /* margin-bottom: 45px; */
}

.connect-section {
  padding-top: 30px;
  border-top: 1px solid var(--main-font);
}

.home-button {
  display: flex;
  justify-content: center;
}

.item {
  background-color: var(--foreground);
}

.home-profile-pic {
  max-width: 275px;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 500px;
}

.short-bio,
p.featured-project,
p.second-project {
  font-size: 1.3rem;
  text-align: justify;
  height: fit-content;
}

p.second-project {
  margin-left: 5px;
}

img.featured-project {
  max-width: 100%;
}

img.programming-bitcoin {
  max-width: 80%;
}

.featured-link {
  text-align: end;
}

.btn.btn-dark {
  box-shadow: var(--main-font) 1px 1px 4px;
}
.btn.btn-dark:hover {
  box-shadow: none;
}

.btn {
  height: min-content;
}

img.education {
  max-width: 120px;
  height: auto;
}

div.education {
  font-size: 1.1remrem;
  text-align: center;
  font-weight: 600;
}

p.education-description {
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 400;
}

.education.row {
  margin: 20px 0;
}

.fa-brands {
  font-size: 5em;
}

.fa-twitter {
  color: rgb(29, 155, 240);
}

.fa-github {
  color: var(--main-font);
}

.fa-linkedin {
  color: rgb(37, 104, 178);
}

.fa-arrow-up-right-from-square {
  /* color: var(--main-font); */
  font-size: 0.6rem;
  margin-left: 5px;
}

.fa-house {
  font-size: 0.8rem;
  margin-left: 5px;
}

span.book-title {
  text-decoration: underline;
}

.arrow-section {
  padding-bottom: 50px;
}

.fa-github.projects-page {
  font-size: 2.2rem;
}

@media screen and (max-width: 768px), screen and (min-width: 576px) {
  div.education {
    text-align: center;
    margin-bottom: 10px;
  }
}

img.resume {
  max-width: 100%;
  height: auto;
}

img.projects-page {
  max-height: 300px;
  max-width: 80%;
}

.project-page-description {
  font-size: 1.1rem;
}

.project-title {
  font-size: 2rem;
}

@media screen and (min-width: 577px), screen and (max-width: 991px) {
  .btn {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 576px) {
  .section-header {
    margin-bottom: 30px;
  }
  .section-container {
    /* padding: 0; */
  }
  img.home-profile-pic {
    margin-bottom: 30px;
  }
  /* .home-buttons {
    padding-bottom: 45px;
  } */
  .short-bio {
    margin-bottom: 30px;
  }
  .btn {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .buttons:nth-of-type(2) {
    padding-top: 10px;
  }

  .arrow,
  .bounce {
    display: none;
  }

  .arrow-section {
    padding: 0;
    padding-top: 10px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .short-bio {
    font-size: 1.3rem;
  }
  div.featured-project,
  div.second-project {
    font-size: 1.3rem;
  }
  .btn {
    font-size: 1.55rem;
  }
}

/* arrow bounce taken from  https://codepen.io/bisaillonyannick/pen/pvZeGg*/
.arrow {
  text-align: center;
  margin: 8% 0;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
